export async function requestNotificationPermission(
  vapidPublicKey: string,
  isRaw: boolean,
) {
  if (!('Notification' in window)) {
    console.error('This browser does not support notifications.');
    return;
  }

  const permission = await Notification.requestPermission();

  if (permission !== 'granted') {
    console.error('Notification permission denied.');
    return;
  }

  // Register the service worker and subscribe for push notifications
  return await registerServiceWorkerAndSubscribe(vapidPublicKey, isRaw);
}

async function registerServiceWorkerAndSubscribe(
  vapidPublicKey: string,
  isRaw: boolean,
) {
  if (!('serviceWorker' in navigator)) {
    console.error('Service workers are not supported in this browser.');
    return;
  }

  try {
    const workerUrl = isRaw ? '/worker.js' : '/default.worker.js';
    const registration = await navigator.serviceWorker.register(workerUrl);

    // Check if the user is already subscribed
    const existingSubscription =
      await registration.pushManager.getSubscription();

    if (existingSubscription) {
      console.log('User is already subscribed to push notifications.');
      return existingSubscription; // No need to subscribe again
    }

    // Subscribe for push notifications if not already subscribed
    const newSubscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
    });

    // Send the new subscription to your Laravel backend
    await fetch('https://api.bp.news/api/save-push-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newSubscription),
    });

    return newSubscription;
  } catch (error) {
    console.error('Error registering service worker or subscribing:', error);
  }
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; i++) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
